import React from "react"
import { Link } from "gatsby"
import "./main-navigation.module.scss"
import { NavigationLinksOutput } from "../../contexts/settings/Navigation/navigation-types"
import { useLayout } from "contexts/components/LayoutContext"

interface MainNavigationProps {
  navItem: NavigationLinksOutput
  index: number
  navCount: number
}

export const MainNavigation = ({ navItem }: MainNavigationProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const { slug, linkText, externalLink } = navItem
  const path = linkedPagesUrlPathCodex[slug] || "404"
  const newSlug = path.startsWith("/") ? path : `/${path}`
  return (
<>
{externalLink ?
<a className="fr-header__link" href={externalLink} target="_blank">
  {linkText}
</a>
:
<Link
to={newSlug}
className="fr-header__link"
activeClassName="fr-header__link--current"
>
{linkText}
</Link>
}
    </>
  )
}
