import React, { useEffect, ReactNode } from "react"
import { Helmet } from "react-helmet"
import { setTrackingCookies } from "../assets/helper/setTrackingCookies"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { useLogos } from "../hooks/queries/settings/useLogos"
import { Footer } from "./footer/Footer"
import { SecondaryHeader } from "./secondary-header/SecondaryHeader"
import { Header } from "./main-header/Header"
import { SideNav } from "./side-nav/SideNav"
import { useNavigationItems } from "../hooks/useNavigationItems"
import { useGeneralSettings } from "../hooks/useGeneralSettings"
import { LayoutContext } from "contexts/components/LayoutContext"
import { MainNavigation } from "./main-navigation/MainNavigation"
import scrollToElement from "scroll-to-element"
import { appendScript, appendInlineScript } from "../assets/helper/appendScript"
import { TrackingNumberModalProvider } from "contexts/TrackingNumberModalContext"
import { useSideNav } from "hooks/useSideNav"

interface LayoutProps {
  children: ReactNode
  pathName: string
  location: string
  hash?: string
  linkedPagesUrlPathCodex: Record<string, string>
  mobilePreload?: string
  parentSlug?: string
  isHomepage?: boolean
}

export const Layout = ({
  children,
  location,
  pathName,
  hash,
  mobilePreload,
  linkedPagesUrlPathCodex,
  parentSlug,
  isHomepage = false,
}: LayoutProps) => {
  const { isNavOpen } = useSideNav()
  const { size, isTablet } = useWindowDimensions()
  const slug =
    location.startsWith("/") && location !== "/"
      ? location.substring(1)
      : "home"
  const scrollClass = "page-content--scroll-version"

  const { customCSSUrl, scripts, domainVerification } = useGeneralSettings()

  const {
    headerNavigation,
    footerNavigation,
    creditsNavigation,
    sideNavigation,
    secondaryHeaderNavigation,
  } = useNavigationItems()
  const { footerLogo, headerLogo, sideNavLogo } = useLogos()

  useEffect(() => {
    setTrackingCookies()
    if (window) {
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormSubmit"
        ) {
          console.log("Form Submitted!", event)
          window.localStorage.setItem("leadSaved", "True")
        }
      })
    }
    if (scripts) {
      scripts?.modular_content.map((script, index) => {
        script.elements.src_value?.value !== "" &&
          appendScript({
            id: `globalScript-${index + 1}`,
            scriptToAppend: script.elements.src_value?.value || "",
            isAsync: true,
          })

        script.elements.script?.value !== "" &&
          appendInlineScript({
            id: `globalScriptInline-${index + 1}`,
            scriptToAppend: script.elements.script?.value || "",
            isAsync: false,
          })
      })
    }
  }, [])

  useEffect(() => {
    if (!hash) return
    let mounted = true

    setTimeout(() => {
      if (mounted) {
        scrollToElement(hash, {
          duration: 1000,
          offset: -200,
        })
      }
    }, 200)

    return () => {
      mounted = false
    }
  }, [hash])

  let navItems = isTablet ? headerNavigation.slice(2) : headerNavigation

  return (
    <LayoutContext.Provider
      value={{
        linkedPagesUrlPathCodex,
        breadcrumbLocation: pathName,
        slug: location,
        parentSlug,
        isHomepage,
      }}
    >
      <div className={`wrapper wrapper--${slug === "/" ? "home" : slug}`}>
        <Helmet>
          {domainVerification !== "" && (
            <meta
              name="google-site-verification"
              content={`${domainVerification}`}
            />
          )}
          {customCSSUrl && <link rel="stylesheet" href={customCSSUrl} />}
          {mobilePreload && size < 768 && (
            <link rel="preload" as="image" href={mobilePreload} />
          )}
        </Helmet>
        <TrackingNumberModalProvider>
          <SecondaryHeader
            secondaryHeaderNavigation={secondaryHeaderNavigation}
          />

          <Header logo={headerLogo}>
            {headerNavigation.length > 0 && (
              <nav className={"fr-header__nav"}>
                {navItems.map((navItem, i) => {
                  return (
                    <MainNavigation
                      key={navItem.id}
                      navItem={navItem}
                      index={i}
                      navCount={headerNavigation.length}
                    />
                  )
                })}
              </nav>
            )}
          </Header>
        </TrackingNumberModalProvider>

        <main className={`page-content ${scrollClass}`}>
          <>{children}</>
        </main>

        {sideNavigation.length > 0 && isNavOpen && (
          <SideNav navItems={sideNavigation} logo={sideNavLogo} />
        )}

        <Footer
          logo={footerLogo}
          creditsNavigation={creditsNavigation}
          footerNavigation={footerNavigation}
        />
      </div>
    </LayoutContext.Provider>
  )
}
