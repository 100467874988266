import React from "react"
import { SocialIcons } from "components/social-icons/SocialIcons"
import { useGeneralSettings } from "hooks/useGeneralSettings"

export const SocialMedia = () => {
  const { socialMedia } = useGeneralSettings()

  return (
    <div className="side-nav__icons">
      {socialMedia.map(
        ({ linkUrl, mediaOutlet, customIcon, customOutletName, id }) => (
          <SocialIcons
            href={linkUrl}
            socialMediaOutlet={mediaOutlet}
            customIcon={customIcon}
            customOutletName={customOutletName}
            key={id}
            whereAmI="--side-nav"
          />
        )
      )}
    </div>
  )
}
