import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useSideNav } from "hooks/useSideNav"
import "../side-nav.module.scss"
import "./sub-nav.module.scss"
import { ChildPagesFormatted } from "contexts/settings/Navigation/navigation-types"
import { useLayout } from "contexts/components/LayoutContext"
import { SubNavChildren } from "./SubNavChildren"
import { GoTriangleDown } from "react-icons/go"

interface SubNavProps {
  id: string
  linkText: string
  parentSlug: string
  childPages: ChildPagesFormatted[]
}

export const SubNav = ({
  id,
  parentSlug,
  childPages,
  linkText,
}: SubNavProps) => {
  const { breadcrumbLocation, linkedPagesUrlPathCodex } = useLayout()

  const { handleCloseNav, isNavOpen, activeSubNavId, handleToggleSubNav } =
    useSideNav()

  const isOpen = activeSubNavId === id
  const [isLocation, setLocation] = useState(false)

  const handleSubNav = (activeItemId: string) => {
    handleToggleSubNav(activeItemId)
  }

  const checkChildElements = (childPages: ChildPagesFormatted[]) => {
    const HOMEPAGE_SLUG = "/"
    childPages.forEach(({ slug }) => {
      const pageSlug = slug.startsWith("/") ? slug : `/${slug}`
      const parentPageSlug = parentSlug.startsWith("/")
        ? parentSlug
        : `/${parentSlug}`

      const isCurrentChildPageLocation = pageSlug === breadcrumbLocation
      const isCurrentParentLocation = parentPageSlug === breadcrumbLocation
      const isParentNotHomepage = parentPageSlug !== HOMEPAGE_SLUG
      const isWithinParentAndNotHomepage =
        isCurrentParentLocation && isParentNotHomepage

      if (isCurrentChildPageLocation || isWithinParentAndNotHomepage) {
        handleToggleSubNav(id)
        setLocation(true)
        return
      }
    })
  }

  useEffect(() => {
    let mounted = true
    if (!isNavOpen) {
      setTimeout(() => {
        if (mounted) {
          if (isOpen && !isLocation) {
            handleToggleSubNav("")
          } else if (!isOpen && isLocation) {
            handleToggleSubNav(id)
          }
        }
      }, 300)

      return
    }

    if (mounted && !isOpen && !isLocation) checkChildElements(childPages)
    return () => {
      mounted = false
    }
  }, [isOpen, isLocation, isNavOpen, id])

  const isNavLinkActiveClassName =
    isOpen || isLocation ? "side-nav__link-active" : ""

  const closed_NavLink_and_is_current_location =
    !isOpen && isLocation ? "side-nav__link-active--closed" : ""

  if (parentSlug) {
    const pageSlug = linkedPagesUrlPathCodex[parentSlug] || "404"
    return (
      <li role="none">
        <span
          className={`side-nav__link ${isNavLinkActiveClassName} ${closed_NavLink_and_is_current_location} side-nav__sub-nav-parent`}
          onClick={() => handleSubNav(id)}
          role="treeitem"
          aria-expanded={`${isOpen}`}
          aria-owns={`id-${linkText
            .toLowerCase()
            .replaceAll(" ", "_")}-subtree`}
        >
          <Link
            to={pageSlug?.startsWith("/") ? `${pageSlug}` : `/${pageSlug}`}
            onClick={handleCloseNav}
          >
            {linkText}
          </Link>
          <button className="side-nav__chevron" aria-label="Drop Down Arrow">
            <GoTriangleDown />
          </button>
        </span>
        {childPages.length > 0 && (
          <SubNavChildren
            id={`id-${linkText.toLowerCase().replaceAll(" ", "_")}-subtree`}
            aria-label={`${linkText.toLowerCase().replaceAll(" ", "_")}`}
            childPages={childPages}
            isOpen={isOpen}
            handleCloseNav={handleCloseNav}
          />
        )}
      </li>
    )
  } else {
    return (
      <li role="none">
        <button
          className={`side-nav__link ${isNavLinkActiveClassName} ${closed_NavLink_and_is_current_location} side-nav__sub-nav-parent--button`}
          onClick={() => handleSubNav(id)}
          role="treeitem"
          aria-expanded={`${isOpen}`}
          aria-owns={`id-${linkText
            .toLowerCase()
            .replaceAll(" ", "_")}-subtree`}
        >
          {linkText}
          <span className="side-nav__chevron">
            <GoTriangleDown />
          </span>
        </button>
        {childPages.length > 0 && (
          <SubNavChildren
            id={`id-${linkText.toLowerCase().replaceAll(" ", "_")}-subtree`}
            aria-label={`${linkText.toLowerCase().replaceAll(" ", "_")}`}
            childPages={childPages}
            isOpen={isOpen}
            handleCloseNav={handleCloseNav}
          />
        )}
      </li>
    )
  }
}
