import React from "react"
import { useSideNav } from "hooks/useSideNav"

interface SideNavOverlayProps extends React.ComponentPropsWithoutRef<"div"> {
  handleClose: () => void
  isSideNavHovered: boolean
}

export const SideNavOverlay = React.forwardRef<
  HTMLDivElement,
  SideNavOverlayProps
>(({ handleClose, isSideNavHovered }, ref) => {
  const { isClosing } = useSideNav()
  return (
    <div
      ref={ref}
      className={`side-nav__overlay 
    ${isClosing ? "side-nav__overlay--closed" : "side-nav__overlay--open"}`}
      onClick={handleClose}
      onMouseEnter={() => {
        isSideNavHovered && handleClose()
      }}
    ></div>
  )
})

SideNavOverlay.displayName = "SideNavOverlay"
