import React, { useState, useCallback } from "react"
import { useSideNav } from "hooks/useSideNav"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { NavigationLinksOutput } from "contexts/settings/Navigation/navigation-types"
import { ImageBuilder } from "_types/AssetsImage"
import { Button } from "components/button"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { ButtonParser } from "components/button/ButtonParser"
import { ContactNumberGrid } from "./contact-numbers/ContactNumberGrid"
import { SocialMedia } from "./SocialMedia"
import { NavItems } from "./NavItems"
import "./side-nav.module.scss"
import { SingleContactNumber } from "./contact-numbers/SingleContactNumber"
import { SideNavContainer } from "./SideNavContainer"
import { getSideNavCloseIcon } from "assets/helper/icons"

interface SideNavProps {
  navItems: NavigationLinksOutput[]
  logo?: ImageBuilder
}

const IMAGE_AUTO_FORMAT_LOSSLESS_COMPRESSION_KENTICO_PARAMS =
  "?fm=pjpg&auto=format&lossless=1&q=60&w=200"

export const SideNav = ({ navItems, logo }: SideNavProps) => {
  const { integrationButton } = useIntegrationsSettings()
  const { handleCloseNav } = useSideNav()
  const { trackingNumbers, socialMedia, customTrackingNumberCtaButtonText } =
    useGeneralSettings()
  const [sideNavHovered, setSideNaveHovered] = useState(false)

  const handleOnClose = useCallback(() => {
    handleCloseNav()
    sideNavHovered && setSideNaveHovered(false)
  }, [handleCloseNav, sideNavHovered])

  return (
    <SideNavContainer
      isSideNavHovered={sideNavHovered}
      setSideNaveHovered={setSideNaveHovered}
      handleOnClose={handleOnClose}
    >
      <div className="side-nav__header">
        {logo && (
          <img
            src={`${logo.url}${IMAGE_AUTO_FORMAT_LOSSLESS_COMPRESSION_KENTICO_PARAMS}`}
            alt={logo.description}
            loading="lazy"
            className="side-nav__logo"
          />
        )}
        <Button
          variant="flashy-icon"
          onClick={handleOnClose}
          extraClassNames="side-nav__close-button"
          aria-label="close side menu"
        >
          {getSideNavCloseIcon()}
        </Button>
      </div>

      {navItems.length > 0 && (
        <NavItems navItems={navItems} handleOnClose={handleOnClose} />
      )}

      {integrationButton && integrationButton.length > 0 && (
        <div className="side-nav__buttons">
          <ButtonParser buttons={integrationButton} />
        </div>
      )}

      {trackingNumbers.length >= 1 && (
        <div className="side-nav__contact-us">
          <h4>{customTrackingNumberCtaButtonText || "Or Give Us a Call:"}</h4>
          <SingleContactNumber />

          {trackingNumbers.length > 1 && <ContactNumberGrid />}
        </div>
      )}

      {socialMedia.length > 0 && <SocialMedia />}
    </SideNavContainer>
  )
}
