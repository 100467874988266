import React from "react"
import { SecondaryHeaderLaptop } from "./SecondaryHeaderLaptop"
import { SecondaryHeaderMobile } from "./SecondaryHeaderMobile"
import "./secondary-header.module.scss"

export const SecondaryHeader = SecondaryHeaderNavigation => {
  return (
    <section className="secondary-header">
      <SecondaryHeaderLaptop navigationItems={SecondaryHeaderNavigation} />
      <SecondaryHeaderMobile />
    </section>
  )
}
