import React from "react"
import { CgArrowDown } from "react-icons/cg"
import { CgArrowUp } from "react-icons/cg"
import { useSideNav } from "hooks/useSideNav"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import "./side-nav.module.scss"
import { RemoveScrollBar } from "react-remove-scroll-bar"
import { FocusOn } from "react-focus-on"
import { SideNavOverlay } from "./SideNavOverlay"

interface SideNavProps {
  children: React.ReactNode
  isSideNavHovered: boolean
  setSideNaveHovered: (isHovered: boolean) => void
  handleOnClose: () => void
}

export const SideNavContainer = ({
  isSideNavHovered,
  setSideNaveHovered,
  handleOnClose,
  children,
}: SideNavProps) => {
  const asideRef = React.useRef<HTMLElement>(null)
  const overlayRef = React.useRef<HTMLDivElement>(null)
  const [indicatorDirection, setIndicatorDirection] = React.useState(false)
  const { isClosing, handleCloseNav } = useSideNav()
  const { height } = useWindowDimensions()
  const { sideNavBackgroundColor } = useColorAreaSettings()
  const scrollClass = "side-nav--scroll-version"

  const overrideBackground = sideNavBackgroundColor
    ? `${sideNavBackgroundColor}--backgroundColor`
    : ""

  const onScroll = () => {
    if (asideRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = asideRef.current
      scrollTop + clientHeight > scrollHeight - 70
        ? setIndicatorDirection(true)
        : setIndicatorDirection(false)
    }
  }

  return (
    <>
      <RemoveScrollBar />
      <div
        className={`side-nav-wrapper ${
          isClosing ? "side-nav-wrapper--close-nav" : ""
        }`}
      />
      <aside
        className={`side-nav ${
          isClosing ? "side-nav--close-nav" : ""
        } ${scrollClass} ${overrideBackground} scroll`}
        onMouseEnter={() => {
          !isSideNavHovered && setSideNaveHovered(true)
        }}
        onScroll={onScroll}
        ref={asideRef}
      >
        <FocusOn
          onClickOutside={handleCloseNav}
          onEscapeKey={handleCloseNav}
          scrollLock={false}
          shards={[asideRef, overlayRef]}
        >
          <div className="fr-container fr-container--large side-nav__inner">
            <>{children}</>
          </div>
        </FocusOn>
        <div
          className={`side-nav__indicator ${
            isClosing ? "side-nav__indicator--close-nav" : ""
          } `}
        >
          {indicatorDirection ? (
            <span>
              <CgArrowUp />
            </span>
          ) : (
            <span>
              <CgArrowDown />
            </span>
          )}
        </div>
      </aside>
      <SideNavOverlay
        handleClose={handleOnClose}
        ref={overlayRef}
        isSideNavHovered={isSideNavHovered}
      />
    </>
  )
}
