import React from "react"
import { ExternalLink } from "components/external-link/ExternalLink"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { SubNav } from "./sub-nav/SubNav"
import { useLayout } from "contexts/components/LayoutContext"
import { Link } from "gatsby"
import { NavigationLinksOutput } from "contexts/settings/Navigation/navigation-types"
import { useGeneralSettings } from "hooks/useGeneralSettings"

interface NavItemsProps {
  handleOnClose: () => void
  navItems: NavigationLinksOutput[]
}

export const NavItems = ({ navItems, handleOnClose }: NavItemsProps) => {
  const { companyName } = useGeneralSettings()
  const { hasFieldroutesReviews, hasFieldroutesPortal } =
    useIntegrationsSettings()
  const { linkedPagesUrlPathCodex, slug } = useLayout()
  return (
    <nav
      aria-label={`${companyName} - Side Navigation`}
      style={{ width: "100%" }}
      className="side-nav__links-container"
    >
      <ul role="tree" className="side-nav__links">
        {navItems.map(({ id, linkText, slug, childPages, externalLink }) => {
          if (childPages.length > 0) {
            return (
              <SubNav
                id={id}
                key={id}
                parentSlug={slug}
                childPages={childPages}
                linkText={linkText}
              />
            )
          } else if (externalLink) {
            return (
              <li role="none" key={id}>
                <ExternalLink
                  className="side-nav__link"
                  href={externalLink}
                  key={id}
                  role="treeitem"
                >
                  {linkText}
                </ExternalLink>
              </li>
            )
          } else {
            if (!hasFieldroutesReviews && slug === "reviews") {
              return null
            }
            const pageSlug = linkedPagesUrlPathCodex[slug] || "404"
            return (
              <li role="none" key={id}>
                <Link
                  onClick={handleOnClose}
                  to={
                    pageSlug?.startsWith("/") ? `${pageSlug}` : `/${pageSlug}`
                  }
                  activeClassName="side-nav__link-active"
                  className="side-nav__link"
                  role="treeitem"
                >
                  {linkText}
                </Link>
              </li>
            )
          }
        })}
        {hasFieldroutesPortal && (
          <li role="none">
            <a
              href="/login"
              onClick={handleOnClose}
              className={`side-nav__link ${
                slug === "/login" && "side-nav__link-active"
              }`}
              role="treeitem"
            >
              Login
            </a>
          </li>
        )}
      </ul>
    </nav>
  )
}
