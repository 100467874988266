import React from "react"
import { Link } from "gatsby"
import { getLoginIcon } from "assets/helper/icons"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { Button } from "components/button"
import { useTrackingNumberModal } from "hooks/useTrackingNumberModal"
import { TrackingNumberModal } from "components/modal/TrackingNumberModal/TrackingNumberModal"
import { AnchorNumber } from "./AnchorNumber"
import { SecondaryHeader } from "./SecondaryHeader"

export const SecondaryHeaderLaptop = navigationItems => {
  const { handleOpenModal, isModalOpen } = useTrackingNumberModal()
  const {
    hasFieldroutesReviews,
    hasFieldroutesPortal,
    customLinkTextFieldroutesReviews,
    customLinkTextFieldroutesPortal,
  } = useIntegrationsSettings()
  const navItems =
    navigationItems?.navigationItems?.secondaryHeaderNavigation[0]
  const {
    trackingNumbers,
    customTrackingNumberCtaButtonText,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()

  const trackingNumberCTAText =
    customTrackingNumberCtaButtonText || "Call Today!"
  return (
    <>
      <div
        className={`fr-container fr-container--large secondary-header__inner secondary-header__inner--laptop`}
      >
        <div className="secondary-header__portal-links">
          {navItems && (
            <a
              className={
                !hasFieldroutesPortal
                  ? "secondary-header__portal-link secondary-header__portal-link--first"
                  : "secondary-header__portal-link"
              }
              href={navItems.externalLink}
              target="_blank"
            >
              {navItems.linkText}
            </a>
          )}
          {hasFieldroutesPortal && (
            <a
              href="/login"
              className="secondary-header__portal-link secondary-header__portal-link--first"
            >
              {getLoginIcon()}
              {customLinkTextFieldroutesPortal
                ? customLinkTextFieldroutesPortal
                : "Customer Login"}
            </a>
          )}
          {hasFieldroutesReviews && (
            <Link to="/reviews" className="secondary-header__portal-link">
              {customLinkTextFieldroutesReviews
                ? customLinkTextFieldroutesReviews
                : "See Our Reviews"}
            </Link>
          )}
        </div>

        {trackingNumbers.length > 0 &&
          trackingNumbers.length <= 3 &&
          trackingNumbers.map(({ trackingNumber, phoneNumber, id }, i) => {
            if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
            if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return ""
            return (
              <AnchorNumber
                key={id}
                classNameModifier={
                  i === 0 ? " secondary-header__phone-link--first" : ""
                }
                phoneNumber={phoneNumber}
                trackingNumber={trackingNumber}
                label={trackingNumberCTAText}
                showIcon={false}
              />
            )
          })}
        {trackingNumbers.length >= 4 && (
          <>
            <Button
              variant="flashy-icon"
              extraClassNames="secondary-header__phone-button"
              onClick={handleOpenModal}
            >
              {trackingNumberCTAText}
            </Button>
            {!isModalOpen &&
              trackingNumbers.map(
                ({ trackingNumber, phoneNumber, id, label }) => {
                  if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
                  if (!usePhoneNumberNotTrackingNumber && !trackingNumber)
                    return ""
                  return (
                    <AnchorNumber
                      key={id}
                      classNameModifier="--hidden"
                      phoneNumber={phoneNumber}
                      trackingNumber={trackingNumber}
                      label={label}
                      showIcon={false}
                    />
                  )
                }
              )}
          </>
        )}
      </div>
      {trackingNumbers.length >= 4 && isModalOpen && (
        <div className={`fr-tracking-number-modal`}>
          <TrackingNumberModal />
        </div>
      )}
    </>
  )
}
