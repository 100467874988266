import React from "react"
import { getPhoneIcon } from "assets/helper/icons"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { ButtonParser } from "components/button/ButtonParser"
import { Button } from "components/button"
import { TrackingNumberModal } from "components/modal/TrackingNumberModal/TrackingNumberModal"
import { useTrackingNumberModal } from "hooks/useTrackingNumberModal"
import { AnchorNumber } from "./AnchorNumber"

export const SecondaryHeaderMobile = () => {
  const { handleOpenModal, isModalOpen } = useTrackingNumberModal()
  const { integrationButton } = useIntegrationsSettings()

  const {
    trackingNumbers,
    customTrackingNumberCtaButtonText,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()

  const trackingNumberCTAText =
    customTrackingNumberCtaButtonText || "Call Today"

  return (
    <>
      <div
        className={`fr-container secondary-header__inner secondary-header__inner--mobile`}
      >
        {integrationButton && (
          <ButtonParser
            buttons={integrationButton}
            extraClassNames="secondary-header__contact-button"
          />
        )}

        {trackingNumbers.length === 1 &&
          trackingNumbers.map(
            ({ trackingNumber, phoneNumber, svgIcon, id, label }) => {
              if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
              if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return ""
              return (
                <AnchorNumber
                  key={id}
                  phoneNumber={phoneNumber}
                  trackingNumber={trackingNumber}
                  label={label}
                  svgIcon={svgIcon}
                />
              )
            }
          )}
        {trackingNumbers.length >= 2 && (
          <>
            <Button
              variant="solid-secondary"
              extraClassNames="secondary-header__phone-link"
              onClick={handleOpenModal}
            >
              {getPhoneIcon()}
              {trackingNumberCTAText}
            </Button>
            {!isModalOpen &&
              trackingNumbers.map(
                ({ trackingNumber, phoneNumber, id, label }) => {
                  if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
                  if (!usePhoneNumberNotTrackingNumber && !trackingNumber)
                    return ""
                  return (
                    <AnchorNumber
                      key={id}
                      classNameModifier="--hidden"
                      phoneNumber={phoneNumber}
                      trackingNumber={trackingNumber}
                      label={label}
                      showIcon={false}
                    />
                  )
                }
              )}
          </>
        )}
      </div>
      {trackingNumbers.length >= 2 && isModalOpen && (
        <div className={`fr-tracking-number-modal`}>
          <TrackingNumberModal />
        </div>
      )}
    </>
  )
}
