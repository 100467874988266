import React from "react"
import { Link } from "gatsby"
import { useLayout } from "contexts/components/LayoutContext"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"

interface SubNavChildProps {
  slug: string
  handleCloseNav: () => void
  children: React.ReactNode
}

export const SubNavChild = ({
  slug,
  handleCloseNav,
  children,
}: SubNavChildProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const { hasFieldroutesReviews } = useIntegrationsSettings()
  // if pestroutes reviews are off we will not return that menu item
  if (!hasFieldroutesReviews && slug === "reviews") return null
  const pageSlug = linkedPagesUrlPathCodex[slug] || "404"
  return (
    <li role="none" className="side-nav__sub-nav-li">
      <Link
        role="treeitem"
        onClick={handleCloseNav}
        to={pageSlug?.startsWith("/") ? `${pageSlug}` : `/${pageSlug}`}
        activeClassName="side-nav__sub-nav-link--active"
        className="side-nav__sub-nav-link"
      >
        <>{children}</>
      </Link>
    </li>
  )
}
